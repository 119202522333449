<template>
  <div class="products">
    <Head :type=1></Head>
    <Carousel></Carousel>
    <div class="products_con">
      <div class="con_left">
        <div v-for="(item,index) in productList" :key="index">
          <div class="list_bg wow bounceInLeft" :style="{backgroundImage: 'url(' + item.backgroundAddress + ')'}">
            <div :class="['con_list',indexShow == index ? 'con_list1' : '']" @click="choice(index,item)">
              <span>{{item.title}}-{{item.description}}</span>
            </div>
          </div>
        </div>
      </div>
      <div id="content" class="con_right wow bounceInRight	">
        <h3>{{proDetail.title}}</h3>
        <p style="padding:15px 0px;">{{proDetail.description}}</p>
        <div v-html="proDetail.content"></div>
      </div>
    </div>
    <RightSide></RightSide>
    <Footer :num="indexShow" :current="current" @clickFooter="clickChild"></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";
import Carousel from "@/components/Carousel.vue";
import RightSide from "@/components/RightSide.vue";
import { getProduct,getproDetail } from "@/api/common";
export default {
  name: "Products",
  components: {
    Head,
    Footer,
    Carousel,
    RightSide
  },
  data() {
    return {
      indexShow: 0,
      current: true,
      productList: [],
      id: '',
      proDetail: {},
    }
  },
  methods: {
    goMore() {
      this.$router.push('/homeERP')
    },
    choice(index,val) {
      this.indexShow = index
      this.id = val.id
      this.getproDetail()
      document.getElementById('content').scrollIntoView({
        behavior: "smooth",
        block:    "start"
      });
    },
    clickChild(e) {
      this.indexShow = e.index
      this.id = e.id
      this.getproDetail()
      document.getElementById('content').scrollIntoView({
        behavior: "smooth",
        block:    "start"
      });
    },
    getproDetail() {
      getproDetail(this.id).then((res) => {
        if (res.data.code == 200) {
          this.proDetail = res.data.data
        } else {

        }
      });
    },
    // 产品服务列表
    getProduct() {
      let params = {};
      getProduct(params).then((res) => {
        if (res.data.code == 200) {
          this.productList = res.data.data
        } else {
        }
      });
    },
  },
  mounted() {
    this.indexShow = this.$route.query.index
    this.id = this.$route.query.id
    this.getProduct()
    this.getproDetail()
    let wow = new WOW(
      {
        boxClass: 'wow',
        animateClass: 'animated',
        offset: 0,
        mobile: true,
        live: true,
        callback: function (box) {
          console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
        }
      }
    );
    wow.init();
  },
};
</script>
<style scoped lang="less">
.products {
  .products_con {
    width: 1280px;
    margin: 40px auto;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    .con_left {
      width: 260px;
      margin-right: 22px;
    }
    .con_right {
      flex: 1;
      img {
        width: 100%;
      }
    }
    .list_bg {
      width: 260px;
      height: 80px;
      background-size:100% 100%;
      margin-bottom: 20px;
      border-radius: 10px;
      cursor: pointer;
    }
    .con_list {
      width: 260px;
      height: 80px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      padding: 0px 20px;
      box-sizing: border-box;
      span {
        overflow: hidden;
        white-space:nowrap;
        text-overflow: ellipsis;
      }
    }
    .con_list:hover {
      background-color: rgba(0,140,212,0.5);
    }
    .con_list1 {
      background-color: rgba(0,140,212,0.5);
    }
  }
}
</style>
